import { HTMLAttributes, useState } from 'react';
import { UserMenuItem } from '@pelando/components';
import { SunBright } from '@pelando/fontawesome/icons';
import { useRouter } from 'next/router';
import { defaultLocale } from '@/presentation/services/theme';
import { ThemeOption } from '../../../../../domain/entities/Theme';
import {
  getSavedThemeOption,
  setThemeByUserOption,
} from '../../../../../infra/theme';
import { useTranslation } from '../../../../hooks/useTranslation';

type ThemePickerItemProps = {
  showAuthModal?: () => void;
} & HTMLAttributes<HTMLElement> & { onClick?: () => void };

export const ThemePickerItem = ({
  showAuthModal,
  ...htmlProps
}: ThemePickerItemProps) => {
  const { t } = useTranslation('header');
  const { locale } = useRouter();
  const [currentTheme, updateTheme] = useState(getSavedThemeOption());

  const lightTheme = defaultLocale(locale)
    ? ThemeOption.LIGHT
    : ThemeOption.LIGHT_NINJA;

  const darkTheme = defaultLocale(locale)
    ? ThemeOption.DARK
    : ThemeOption.DARK_NINJA;

  const handleThemeChange = (theme: ThemeOption) => {
    htmlProps.onClick?.();
    if (showAuthModal) {
      showAuthModal();
      return;
    }

    setThemeByUserOption(theme);
    updateTheme(theme);
  };

  return (
    <UserMenuItem
      data-testid="menu-theme"
      icon={SunBright}
      label={t('menu-theme')}
      accordionOptions={[
        {
          label: t('menu-theme-light'),
          onClick: () => handleThemeChange(lightTheme),
          isSelected: currentTheme === lightTheme,
        },
        {
          label: t('menu-theme-dark'),
          onClick: () => handleThemeChange(darkTheme),
          isSelected: currentTheme === darkTheme,
        },
        {
          label: t('menu-theme-automatic'),
          onClick: () => handleThemeChange(ThemeOption.AUTO),
          isSelected: currentTheme === ThemeOption.AUTO,
        },
      ]}
    />
  );
};
