import { NotificationBarProps } from '@pelando/components';
import { Tags } from '@pelando/fontawesome/icons';
import dynamic from 'next/dynamic';
import { isComparatorHomeEnabled } from '@/presentation/services/featureFlags';
import ComparatorIcon from '@/resources/comparator/logos/icon.svg?component';
import { useCallback } from 'react';
import { emitTrackingEvent } from '@/infra/analytics/emitTrackingEvent';
import {
  EventCategory,
  GeneralProductEvents,
  ProductComponentNames,
} from '@/domain/events/analytics';

import { useRouter } from 'next/router';
import { LoggedUser } from '../../../domain/entities/User';
import { HeaderRightCornerContent } from './components/HeaderRightCornerContent';
import type { SearchbarProps } from './components/HeaderSearchBar';
import {
  HeaderContainer,
  HeaderContent,
  HeaderContentWrapper,
  HeaderLogo,
  HeaderStores,
  HeaderStoresItem,
  HeaderText,
} from './styles';
import { getAllStoresUrl, getComparatorHomeUrl } from '../../services/urls';
import {
  HeaderItemLink,
  StoreDealIcon,
} from './components/HeaderRightCornerContent/styles';
import { useTranslation } from '../../hooks/useTranslation';
import { AuthenticationStatus } from '../../../infra/api/auth/types/GetLoggedUser';

export type HeaderContentProps = {
  currentUser?: LoggedUser | null;
  searchHandler: SearchbarProps;
  logoUrl: string;
  notificationBarProps: NotificationBarProps;
  authModal: () => void;
  authenticationStatus: AuthenticationStatus;
  logout: () => void;
  hasBanner: boolean;
  alertsCount?: number;
  isSearchPage?: boolean;
  hasContextAlertRegister: boolean;
};

const HeaderSearchBar = dynamic(() =>
  import('./components/HeaderSearchBar').then((mod) => mod.HeaderSearchBar)
);

export function Header({
  currentUser,
  searchHandler,
  logoUrl,
  logout,
  notificationBarProps,
  authModal,
  authenticationStatus,
  hasBanner,
  alertsCount,
  isSearchPage,
  hasContextAlertRegister,
}: HeaderContentProps) {
  const { t } = useTranslation('header');
  const { locale } = useRouter();
  const isPending = authenticationStatus === AuthenticationStatus.PENDING;
  const isLogged = authenticationStatus === AuthenticationStatus.LOGGED;

  const handleComparatorClick = useCallback(() => {
    emitTrackingEvent({
      category: EventCategory.GeneralProduct,
      name: GeneralProductEvents.ClickToGoToComparatorHome,
      extra: {
        component_source: ProductComponentNames.Header,
      },
    });
  }, []);

  return (
    <HeaderContainer data-is-search-page={isSearchPage} $hasBanner={hasBanner}>
      <HeaderContent>
        <HeaderLogo url={logoUrl} locale={locale || ''} />
        <HeaderContentWrapper data-logged={isLogged}>
          <HeaderStores>
            <HeaderItemLink
              url={getAllStoresUrl(locale)}
              title={t('store-link')}
            >
              <HeaderStoresItem>
                <StoreDealIcon icon={Tags} />
                <HeaderText>{t('store-link')}</HeaderText>
              </HeaderStoresItem>
            </HeaderItemLink>
            {isComparatorHomeEnabled() ? (
              <HeaderItemLink
                url={getComparatorHomeUrl(locale)}
                onClick={handleComparatorClick}
              >
                <HeaderStoresItem>
                  <ComparatorIcon />
                  <HeaderText>{t('comparator-text')}</HeaderText>
                </HeaderStoresItem>
              </HeaderItemLink>
            ) : undefined}
          </HeaderStores>
          <HeaderSearchBar isLoading={isPending} {...searchHandler} />
          <HeaderRightCornerContent
            notificationBarProps={notificationBarProps}
            onSignInClick={authModal}
            userImage={currentUser?.image?.url || ''}
            logout={logout}
            userNickname={currentUser?.nickname || ''}
            isLogged={isLogged}
            isLoading={isPending}
            alertsCount={alertsCount}
            hasContextAlertRegister={hasContextAlertRegister}
          />
        </HeaderContentWrapper>
      </HeaderContent>
    </HeaderContainer>
  );
}
