import { styled, css } from 'styled-components';
import { H3Heavy, MediaQuery, Theme } from '@pelando/components/styles';
import { AvatarImage } from '@pelando/components/components/Avatar/style';
import { UserMenuAvatar } from '../style';

const nicknameStyle = css`
  overflow: hidden;
  position: relative;
  margin-right: 12px;

  &:after {
    content: '';
    display: block;
    width: 40px;
    height: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to right,
      rgba(${Theme.colors.Juliet}, 0),
      rgba(${Theme.colors.Juliet}, 100%)
    );
  }
`;

export const MobileUserTitle = styled.p`
  ${H3Heavy}
  color: rgb(${Theme.colors.Alpha});
  padding: 12px 0;

  ${nicknameStyle}
`;

export const MobileUserAvatar = styled(UserMenuAvatar)`
  display: block;

  ${AvatarImage} {
    width: 40px;
    height: 40px;
  }
`;

export const MobileHeader = styled.div`
  padding: 64px 24px 40px 24px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: none;
  }
`;

export const UserMenuTitle = styled.p`
  display: none;
  text-decoration: none;

  ${H3Heavy}
  font-size: 1rem;
  line-height: 1.25rem;
  color: rgb(${Theme.colors.Brand});
  justify-content: space-between;

  ${nicknameStyle}

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: flex;

    flex: 1 0 auto;
    padding: 32px 24px 16px;
  }
`;
